<template>
  <header>
    <van-sticky>
      <h1 class="logo">
        <img @click="goHome" :src="require(`@/assets/image/ui/svg/logo.svg`)" alt="锡林花商城" />
        <span>塞上江南 河套好面</span>
      </h1>
      <i class="icon-nav" @click="showPopup"></i>
    </van-sticky>
    <div v-if="$route.name !== 'contact' && $route.name !== 'home'">
      <img width="100%" :src="require(`@/assets/image/ui/webp/header-ad.webp`)" alt="" />
    </div>
    <van-popup position="left" v-model="show" :style="{ height: '100%', width: '61.8%' }">
      <nav-box @hide="hidePopup" />
    </van-popup>
  </header>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 组件名称 from '组件路径';
import NavBox from '@/components/layout/NavBox.vue';
import { Popup, Sticky } from 'vant';
export default {
  name: 'TopBar',
  //引入组件
  props: [],
  //引入混淆
  mixins: [],
  //import引入的组件需要注入到对象中才能使用
  components: {
    NavBox,
    'van-popup': Popup,
    'van-sticky': Sticky,
  },
  data() {
    //这里存放数据
    return {
      show: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    showPopup() {
      this.show = true;
    },
    hidePopup() {
      this.show = false;
    },
    goHome() {
      this.$router.push('/')
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  //生命周期 - 创建之前
  beforeCreate() { },
  //生命周期 - 挂载之前
  beforeMount() { },
  //生命周期 - 更新之前
  beforeUpdate() { },
  //生命周期 - 更新之后
  updated() { },
  //生命周期 - 销毁之前
  beforeDestroy() { },
  //生命周期 - 销毁完成
  destroyed() { },
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() { },
};
</script>

<style lang="scss" scoped>
header {
  .logo {
    height: 40px;
    line-height: 40px;
    padding: 20px 50px 20px 20px;
    text-align: left;
    background-color: #fff;
    color: #159c43;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      position: relative;
      top: 5px;
      font-size: 20px;
      font-weight: 900;

      &::before {
        position: relative;
        top: -2px;
        margin: 0 10px;
        content: '';
        display: inline-block;
        width: 2px;
        height: 9px;
        background-color: #0000004d;
      }
    }
  }

  .icon-nav {
    position: absolute;
    top: 34px;
    right: 20px;
    font-size: 26px;
    color: #159c43;
  }
}
</style>
