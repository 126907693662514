<template>
  <div class="classify-list">
    <router-link v-for="path in data" :key="path" :to="`/product/${path}`">
      <img
        width="100%"
        :src="require(`@/assets/image/product/classify/webp/${path}.webp`)"
        loading="lazy"
        alt="item.path"
      />
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'classify-list',
  data() {
    //这里存放数据
    return {
      data: [
        'flour',
        'rice',
        'coarse',
        'hot-dishes',
        'nei-meng-niu-rou-gan',
        'kui-gua-zi-you',
        'gift',
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.classify-list{
  margin: 20px;
  img{
    margin-bottom: 20px;
  }
}
</style>
