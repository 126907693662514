<template>
  <div id="app">
    <TopBar />
    <transition name="scale" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
// @ is an alias to /src
import TopBar from '@/components/layout/TopBar.vue';

export default {
  name: 'MainView',
  components: {
    TopBar,
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
