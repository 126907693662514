<template>
  <div class="home">
    <div class="banner">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="index in 2" :key="index">
          <img width="100%" height="100%" :src="require(`@/assets/image/banner/webp/${index}.webp`)" :alt="index"
            loading="lazy" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="recommend">
      <van-row gutter="20" class="recommend-item">
        <van-col span="14">
          <dl>
            <dd class="title">雪花一号高筋粉</dd>
            <dt class="sub-title">蛋白质含量更高</dt>
            <dt class="describe">
              源自内蒙古河套平原产区,面粉取自中心胚乳，粉质洁白细腻。蛋白质含量较高，适合做馒头、饺子、干面包、馄饨皮等。
            </dt>
            <dt>
              <router-link to="/product/flour/details/xue-hua-1-hao">点击立刻下单</router-link>
            </dt>
          </dl>
        </van-col>
        <van-col span="10">
          <img width="100%" height="100%" :src="require(`@/assets/image/home/webp/recommend-1.webp`)" alt="雪花一号高筋粉"
            loading="lazy" />
        </van-col>
      </van-row>
      <van-row gutter="20" class="recommend-item">
        <van-col span="8">
          <img width="100%" height="100%" :src="require(`@/assets/image/home/webp/recommend-2.webp`)" alt="巴盟农家黄小米"
            loading="lazy" />
        </van-col>
        <van-col span="16">
          <dl>
            <dd class="title">巴盟农家黄小米</dd>
            <dt class="sub-title">甄选·敖汉旗金苗K1</dt>
            <dt class="describe">
              地方传统小米品种，种植历史悠久。金苗K1去壳后的产物颗粒浑圆，米粒略小，色泽金黄熬制时会形成细腻绵绸的米油。淡淡清香的味道是自然的馈赠煮出米油浓厚的小米粥。
            </dt>
            <dt>
              <router-link to="/product/coarse/details/yue-zi-xiao-mi">点击立刻下单</router-link>
            </dt>
          </dl>
        </van-col>
      </van-row>
      <van-row gutter="20" class="recommend-item">
        <van-col span="24">
          <img width="100%" height="100%" :src="require(`@/assets/image/home/webp/recommend-3.webp`)" alt="礼盒"
            loading="lazy" />
          <p>
            <router-link to="/product/gift">点击立刻下单</router-link>
          </p>
        </van-col>
      </van-row>
    </div>
    <div class="introduce">
      <dl class="introduce-item">
        <dd>
          <h3 class="title">
            巴盟农家
            <img :src="require(`@/assets/image/ui/svg/logo.svg`)" alt="锡林花商城" />
          </h3>
        </dd>
        <dt class="sub-title">全平台展现</dt>
        <dt class="describe">
          [线上+线下] 实体与互联网的混合经营模式
          <br />
          加快品牌建设，引领新风尚
        </dt>
        <dt>
          <img width="100%" height="100%" :src="require(`@/assets/image/home/webp/introduce-1.webp`)" alt="全平台展现"
            loading="lazy" />
        </dt>
      </dl>
      <dl class="introduce-item">
        <dd>
          <h3 class="title">
            锡林花供应链
            <img :src="require(`@/assets/image/ui/svg/logo-3.svg`)" alt="锡林花供应链" />
          </h3>
        </dd>
        <dt class="sub-title">全平台展现</dt>
        <dt class="describe">
          [线上+线下] 从源头把控产品品控环节
          <br />
          致力于为千万家庭送上一份
          <br />
          价格亲民的内蒙好礼！
        </dt>
        <dt>
          <img width="100%" height="100%" :src="require(`@/assets/image/home/webp/introduce-2.webp`)" alt="全平台展现"
            loading="lazy" />
        </dt>
        <dt class="other">
          <h3>
            <!-- <span>BRAND</span>
            <span>INTRODUCTION</span> -->
          </h3>
          <p>
            内蒙古锐洋食品有限公司成立于2015年7月,为内蒙古包头市大型商超及机关单位的主要供应商之一；同时肩负着包头市粮食应急储备任务。塞上江南，河套好面！是巴盟农家品牌的口号也是使命；公司成立多年坚持诚实守信的原则经营并深度追溯原粮的原产地，将优质的内蒙食品呈现给大众；
            <br />
            <br />
            2021年，成立锡林花（上海）科技发展有限公司，营销总部位于上海，为内蒙古锐洋食品有限公司的全资控股公司，通过互联网的方式加快推进品牌建设。
            <br />
            <br />
            2022年在内蒙古成立锡林花供应链中心（锡林花（内蒙古）科技发展有限公司）。从源头把控产品品控环节致力于为千万家庭送上一份价格亲民的内蒙好礼。
          </p>
        </dt>
        <dt>
          <img width="100%" height="100%" :src="require(`@/assets/image/home/webp/introduce-3.webp`)" alt="全平台展现"
            loading="lazy" />
        </dt>
        <dt class="score" v-if="false">
          <h3>品牌成绩</h3>
          <ul>
            <li>全国放心粮油示范工程示范配送中心</li>
            <li>2015-2018年度优秀会员</li>
            <li>内蒙古自治区农牧业产业化重点龙头企业</li>
            <li>包头市 “诚信企业内蒙古粮油加工20强”企业</li>
            <li>诚信粮油经销商</li>
          </ul>
        </dt>
        <dt class="resources">
          <van-row gutter="20" class="resources-box">
            <van-col span="8">
              <div class="resources-item">
                <em>工厂研发中心</em>
                <span><b class="num">3000</b><i>m²</i></span>
              </div>
              <img width="100%" height="100%" :src="require(`@/assets/image/home/webp/resources-1.webp`)" alt="工厂研发中心"
                loading="lazy" />
            </van-col>
            <van-col span="8">
              <div class="resources-item">
                <em>仓储物流中心</em>
                <span><b class="num">2000</b><i>m²</i></span>
              </div>
              <img width="100%" height="100%" :src="require(`@/assets/image/home/webp/resources-2.webp`)" alt="仓储物流中心"
                loading="lazy" />
            </van-col>
            <van-col span="8">
              <div class="resources-item">
                <em>产品研发中心</em>
                <span><b class="num">150</b><i>m²</i></span>
              </div>
              <img width="100%" height="100%" :src="require(`@/assets/image/home/webp/resources-3.webp`)" alt="产品研发中心"
                loading="lazy" />
            </van-col>
          </van-row>
        </dt>
      </dl>
    </div>
    <dl class="responsibility">
      <dd>
        <h3 class="title">社会责任</h3>
        <span>2020年锐洋食品疫情期间助力"鹿城"稳定粮价</span>
      </dd>
      <dt>
        <img width="100%" height="100%" :src="require(`@/assets/image/home/webp/video.webp`)" alt="全平台展现"
          loading="lazy" />
        <p>*来源：包头晚报微信公众号</p>
      </dt>
    </dl>
    <dl class="quality">
      <dd>
        <h3 class="title">品质过硬 吃的更放心</h3>
        <span>本企业|通过ISO9001:2015质量管理体系认证</span>
      </dd>
      <dt>
        <van-row class="certificate" gutter="20">
          <van-col span="12" v-for="item in 8" :key="item">
            <img width="100%" height="100%" :src="require(`@/assets/image/home/webp/certificate-${item}.webp`)" alt="证书"
              loading="lazy" />
          </van-col>
        </van-row>
      </dt>
    </dl>
    <dl class="producer">
      <dd>
        <h3 class="title">产地照片</h3>
      </dd>
      <dt>
        <van-row class="area">
          <van-col span="8" v-for="item in 6" :key="item">
            <img width="100%" height="100%" @click="showPreview(item)"
              :src="require(`@/assets/image/home/webp/area-${item}.webp`)" alt="产地照片" loading="lazy" />
          </van-col>
        </van-row>
      </dt>
    </dl>
  </div>
</template>
<script>
import { Swipe, SwipeItem, Col, Row, ImagePreview } from 'vant';
export default {
  name: 'home-page',
  components: {
    'van-swipe': Swipe,
    'van-swipe-item': SwipeItem,
    'van-col': Col,
    'van-row': Row,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  methods: {
    showPreview(item) {
      ImagePreview({
        images: [
          require('@/assets/image/home/webp/area-1.webp'),
          require('@/assets/image/home/webp/area-2.webp'),
          require('@/assets/image/home/webp/area-3.webp'),
          require('@/assets/image/home/webp/area-4.webp'),
          require('@/assets/image/home/webp/area-5.webp'),
          require('@/assets/image/home/webp/area-6.webp'),
        ],
        startPosition: item - 1,
        closeable: true,
      });
    }
  }
};
</script>
<style>
@media screen and (max-width:390px) {
  .resources-item .num {
    font-size: 20px;
  }
}
</style>
<style lang="scss" scoped>
.recommend {
  margin: 20px 20px 80px 20px;

  .recommend-item {
    margin-bottom: 20px;

    p {
      text-align: center;
      margin-top: 10px;
    }

    a {
      color: #a0a0a0;
      border: 1px solid #a0a0a0;
      border-radius: 3px;
      padding: 3px 20px;
    }
  }

  .title {
    position: relative;
    font-size: 26px;
    font-weight: 700;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 15px;
      background: linear-gradient(90deg, #159c43 0%, rgba(21, 156, 67, 0) 100%);
    }
  }

  .sub-title {
    font-size: 18px;
    color: #159c43;
    margin-top: 5px;
    line-height: 30px;
  }

  .describe {
    line-height: 1.5;
    margin-bottom: 10px;
  }
}

.introduce {
  margin: 20px;

  .introduce-item {
    margin-bottom: 80px;
  }

  .title {
    font-size: 36px;
    font-weight: 900;
  }

  .sub-title {
    position: relative;
    font-size: 24px;
    height: 45px;
    font-weight: 900;
    color: #159c43;
    margin: 5px 0 20px 0;
    line-height: 30px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 25px;
      height: 5px;
      background-color: #00000066;
    }
  }

  .describe {
    font-size: 18px;
    font-weight: 700;
    color: #159c43;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .other {
    position: relative;
    margin-top: 30px;

    h3 {
      position: absolute;
      top: 0;
      left: 0;
      background: transparent url('@/assets/image/home/webp/brand.webp') 0 0 no-repeat;
      width: 75px;
      height: 1200px;
      background-size: 25%;

      span {
        font-weight: 900;
        font-size: 30px;
      }

      span:first-child {
        color: #159c43;
        margin-right: 10px;
      }

      span:last-child {
        -webkit-text-stroke: 1px #159c43; // 根据需求不同改变颜色
        -webkit-text-fill-color: transparent;
        color: #fff;
      }
    }

    p {
      background-color: #159c43;
      border-radius: 5px;
      line-height: 1.5;
      font-size: 14px;
      color: #fff;
      padding: 20px;
      min-height: 380px;
      margin-left: 50px;
    }
  }

  .score {
    border-radius: 5px;
    line-height: 1.5;
    font-size: 14px;
    padding: 40px 40px 180px 40px;
    margin: 40px 0;
    color: #fff;
    background: #159c43 url('@/assets/image/home/webp/score-icon.webp') center 90% no-repeat;
    background-size: 25%;

    h3 {
      font-size: 38px;
      font-weight: 900;
      text-align: center;
      margin-bottom: 20px;
    }

    li {
      line-height: 26px;
      list-style: disc;
    }
  }

  .resources {
    .resources-item {
      em {
        display: block;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-bottom: 1px solid #a0a0a0;
      }

      span {
        position: relative;
        display: block;
        font-weight: 700;
        color: #159c43;
        font-size: 34px;
        margin-bottom: 5px;
      }

      i {
        position: absolute;
        right: 0;
        bottom: 5px;
        font-style: normal;
        font-size: 14px;
      }
    }
  }
}

.responsibility {
  margin: 20px 20px 80px 20px;

  .title {
    font-size: 36px;
    font-weight: 900;
    color: #159c43;
  }

  span {
    display: inline-block;
    margin: 10px 0 10px 0;
  }

  p {
    text-align: center;
    font-size: 14px;
    color: #159c43;
    margin-top: 10px;
  }
}

.quality {
  margin: 20px 20px 80px 20px;
  text-align: center;
  color: #159c43;

  .title {
    font-size: 36px;
    font-weight: 900;
  }

  span {
    display: inline-block;
    margin: 10px 0 20px 0;
  }

  img {
    margin-bottom: 20px;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
  }
}

.producer {
  margin: 20px;
  color: #159c43;
  text-align: center;

  .title {
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 20px;
  }
}
</style>
