<template>
  <div class="nav-wrapper">
    <h2 class="phone icon-phone">
      联系我们
      <a href="tel:4001081084">400-108-1084</a>
    </h2>
    <nav class="nav">
      <router-link to="/home" @click.native="hide">首页</router-link>
      <router-link to="/product" @click.native="hide">产品目录</router-link>
      <router-link to="/contact" @click.native="hide">联系我们</router-link>
    </nav>
    <dl class="follow">
      <dd>关注我们</dd>
      <dt>
        <a href="####">
          巴盟农家京东自营
        </a>
      </dt>
      <dt>
        <a href="https://shop.m.jd.com/shop/home?shopId=13348977" target="_blank">
          巴盟农家天猫旗舰店
        </a>
      </dt>
      <dt>
        <a href="####">
          锡林花抖音官方
        </a>
      </dt>
      <dt>
        <a href="https://shop.m.jd.com/shop/home?shopId=13348977" target="_blank">
          锡林花臻选淘宝食品工坊
        </a>
      </dt>
      <dt>
        <a href="https://shop.m.jd.com/shop/home?shopId=13348977" target="_blank">
          锡林花Healthy life官方公众号
        </a>
      </dt>
    </dl>
    <dl class="logo">
      <dd>
        <img :src="require(`@/assets/image/ui/svg/logo.svg`)" alt="锡林花商城" />
      </dd>
      <dd>
        <img :src="require(`@/assets/image/ui/svg/logo-2.svg`)" alt="锡林花" />
      </dd>
      <dt>北方臻品 天然之赐！</dt>
    </dl>
    <div class="copyright">
      <p>© 2023 - {{ new Date().getFullYear() }}<br />内蒙古锐洋食品有限公司<br />锡林花(上海)科技发展有限公司<br />ALL RIGHTS RESERVED.</p>
      <p><a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">蒙ICP备2023001475号-2</a></p>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 组件名称 from '组件路径';
export default {
  //引入组件
  props: [],
  //引入混淆
  mixins: [],
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    hide() {
      this.$emit('hide');
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  //生命周期 - 创建之前
  beforeCreate() { },
  //生命周期 - 挂载之前
  beforeMount() { },
  //生命周期 - 更新之前
  beforeUpdate() { },
  //生命周期 - 更新之后
  updated() { },
  //生命周期 - 销毁之前
  beforeDestroy() { },
  //生命周期 - 销毁完成
  destroyed() { },
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() { },
};
</script>

<style lang="scss" scoped>
.nav-wrapper {
  box-sizing: border-box;
  text-align: left;
  padding: 20px;
  height: 100%;
  overflow: auto;
  background-color: #159c43;
  color: #fff;

  .phone {
    position: relative;
    padding-left: 60px;
    font-size: 20px;
    margin-bottom: 30px;

    &::before {
      font-size: 48px;
      left: 0;
      position: absolute;
    }

    a {
      display: block;
      color: #fff;
      margin-top: 5px;
    }
  }

  .nav {
    padding: 0 0 80px 0;

    a {
      display: block;
      color: #000;
      font-size: 36px;
      line-height: 80px;
      font-weight: 700;

      &.active {
        color: #fff;
      }
    }
  }

  .follow {
    font-size: 16px;

    dd {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    a {
      color: #fff;
      line-height: 30px;
    }
  }

  .logo {
    margin-top: 40px;
    padding: 20px 0;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    font-weight: 700px;
    font-size: 16px;

    dd {
      margin-bottom: 10px;
      display: inline-block;
      width: 40%;

      img {
        text-align: center;
      }
    }
  }

  .copyright {
    margin-top: 20px;
    font-size: 12px;
    line-height: 1.5;

    a {
      color: #fff;
    }
  }
}
</style>
