<template>
  <div class="product-details">
    <img-list :path="title" :num="data[title]" />
  </div>
</template>
<script>
import ImgList from '@/components/layout/ImgList.vue';
import ProductList from '@/assets/data/list.json';
export default {
  name: 'product-details',
  props: ['title'],
  components: {
    ImgList,
  },
  data() {
    //这里存放数据
    return {
      data: ProductList
    };
  },
};
</script>
