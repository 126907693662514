<template>
  <div class="product-list">
    <h3 class="product-title">
      {{ $route.meta.en }}
      <br />
      {{ $route.meta.title }}
    </h3>
    <list-box :data="data" />
  </div>
</template>
<script>
import ListBox from '@/components/layout/ListBox.vue';
export default {
  name: 'product-list',
  components: {
    ListBox,
  },
  data() {
    //这里存放数据
    return {
      data: require(`@/assets/data/${this.$route.meta.classify}.json`),
    };
  }
}
</script>