import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home/index.vue';
import Product from '../views/product/index.vue';
import ProductClassify from '../views/product/classify.vue';
import ProductList from '../views/product/list.vue';
import ProductDetails from '../views/product/details.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '巴盟农家',
    },
    component: Home,
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      title: '产品目录',
    },
    component: Product,
    redirect: '/product/classify',
    children: [
      {
        path: 'classify',
        name: 'product-classify',
        meta: {
          title: '产品目录',
        },
        component: ProductClassify,
      },
      {
        path: 'flour',
        name: 'flour',
        meta: {
          title: '面粉系列',
          en: 'FLOUR SERIES',
          classify: 'flour'
        },
        component: Product,
        redirect: '/product/flour/list',
        children: [
          {
            path: 'list',
            name: 'flour-list',
            meta: {
              title: '面粉系列',
              en: 'FLOUR SERIES',
              classify: 'flour'
            },
            component: ProductList
          },
          {
            path: 'details/:title',
            name: 'flour-detail',
            meta: {
              title: '面粉系列',
              en: 'FLOUR SERIES',
              classify: 'flour'
            },
            component: ProductDetails,
            props(route) {
              return {
                title: route.params.title
              };
            },
          },
        ],
      },
      {
        path: 'rice',
        name: 'rice',
        meta: {
          title: '大米系列',
          en: 'RICE SERIES',
          classify: 'rice'
        },
        component: Product,
        redirect: '/product/rice/list',
        children: [
          {
            path: 'list',
            name: 'rice-list',
            meta: {
              title: '大米系列',
              en: 'RICE SERIES',
              classify: 'rice'
            },
            component: ProductList
          },
          {
            path: 'details/:title',
            name: 'rice-detail',
            meta: {
              title: '大米系列',
              en: 'RICE SERIES',
              classify: 'rice'
            },
            component: ProductDetails,
            props(route) {
              return {
                title: route.params.title
              };
            },
          },
        ],
      },
      {
        path: 'coarse',
        name: 'coarse',
        meta: {
          title: '杂粮系列',
          en: 'SOABETFREALS',
          classify: 'coarse'
        },
        component: Product,
        redirect: '/product/coarse/list',
        children: [
          {
            path: 'list',
            name: 'coarse-list',
            meta: {
              title: '杂粮系列',
              en: 'SOABETFREALS',
              classify: 'coarse'
            },
            component: ProductList
          },
          {
            name: 'coarse-detail',
            path: 'details/:title',
            component: ProductDetails,
            meta: {
              title: '杂粮系列',
              en: 'SOABETFREALS',
              classify: 'coarse'
            },
            props(route) {
              return {
                title: route.params.title
              };
            },
          },
        ],
      },
      {
        path: 'hot-dishes',
        name: 'hot-dishes',
        meta: {
          title: '自热菜系列',
          en: 'SELF HEATING DISH',
          classify: 'hot-dishes'
        },
        component: Product,
        redirect: '/product/hot-dishes/list',
        children: [
          {
            path: 'list',
            name: 'hot-dishes-list',
            meta: {
              title: '自热菜系列',
              en: 'SELF HEATING DISH',
              classify: 'hot-dishes'
            },
            component: ProductList
          },
          {
            path: 'details/:title',
            name: 'hot-dishes-detail',
            component: ProductDetails,
            meta: {
              title: '自热菜系列',
              en: 'SELF HEATING DISH',
              classify: 'hot-dishes'
            },
            props(route) {
              return {
                title: route.params.title
              };
            },
          },
        ],
      },
      {
        path: 'nei-meng-niu-rou-gan',
        name: 'nei-meng-niu-rou-gan',
        meta: {
          title: '内蒙牛肉干',
        },
        component: ProductDetails,
        props() {
          return {
            title: 'nei-meng-niu-rou-gan'
          };
        },
      },
      {
        path: 'kui-gua-zi-you',
        name: 'kui-gua-zi-you',
        meta: {
          title: '葵瓜籽油',
        },
        component: ProductDetails,
        props() {
          return {
            title: 'kui-gua-zi-you'
          };
        },
      },
      {
        path: 'gift',
        name: 'gift',
        meta: {
          title: '礼盒系列',
          en: 'GIFT BOX SERIES',
        },
        component: () =>
          import(
            /* webpackChunkName: "gift" */ '../views/product/gift/index.vue'
          ),
      },
    ],
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: '联系我们',
    },
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/contact/index.vue'),
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title || '锡林花商城';
  next();
});

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
