<template>
  <div class="product">
    <transition name="scale" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>

export default {
  name: 'product-page',
}
</script>