<template>
  <div class="product-list-wrapper">
    <van-row>
      <van-col v-for="item in data" :key="item.id" span="12">
        <router-link class="card-item" :class="{'no-click': item.path === 'rice' || item.path === 'flour' || item.path === 'coarse'}" :to="item.target" >
          <dl>
            <dd>
              <img
                width="100%"
                height="100%"
                :src="
                  require(`@/assets/image/product/${item.path}/webp/${item.id}.webp`)
                "
                :alt="item.title"
                loading="lazy"
              />
            </dd>
            <dt class="card-title">{{ item.title }}</dt>
            <dt v-if="item.path != 'rice' && item.path != 'flour' && item.path != 'coarse'" class="card-weight">净含量：{{ item.weight }}</dt>
          </dl>
        </router-link>
      </van-col>
    </van-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 组件名称 from '组件路径';
import { Col, Row } from 'vant';
export default {
  name: 'list-box',
  //引入组件
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    }
  },
  //引入混淆
  mixins: [],
  //import引入的组件需要注入到对象中才能使用
  components: {
    'van-col': Col,
    'van-row': Row,
  },
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
};
</script>

<style lang="scss" scoped>
.product-list-wrapper{
  margin: 0 20px;
}
.card-item {
  box-shadow: 0px 3px 8px -1px #0000001A;
  display: inline-block;
  padding-bottom: 20px;
  margin: 20px 10px 0 10px;
  text-align: center;
  color: #000;
  &.no-click{
    pointer-events: none;
  }
  .card-title{
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
  }
  .card-weight{
    margin-top: 10px;
    font-size: 12px;
  }
}
</style>
